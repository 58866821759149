#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #e32636;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
}

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #e32636, 0 0 5px #e35864;
    opacity: 1;
    -webkit-transform: rotate(3deg) translateY(-4px);
    -ms-transform: rotate(3deg) translateY(-4px);
    transform: rotate(3deg) translateY(-4px);
}

#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: calc(50vh - 36px);
    right: calc(50vw - 36px);
}

#nprogress .spinner-icon {
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    border-color: rgb(227, 38, 54) transparent transparent rgb(227, 88, 100);
    border-style: solid;
    border-width: 6px;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner .4s linear infinite;
    animation: nprogress-spinner .4s linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .bar, .nprogress-custom-parent #nprogress .spinner {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(1turn);
    }
}
