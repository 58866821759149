.app__menuitem-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background: url('/src/assets/images/gray-marble-textured-background.jpg') center center;
  filter: blur(1px);
  opacity: 0.75;
  border-radius: 15px;
}

.app__menuitem {
  width: 100%;
  margin: 4rem 0;
  position: relative;
  border-radius: 15px;
  text-align: center;
  box-shadow: 10px 10px 4px 0 rgba(0, 0, 0, 0.6);
  padding: 35px 10px;
  transition: box-shadow 550ms;
}

.app__menuitem:hover,
.app__menuitem:focus {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
}

.app__menuitem-number {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -21px;
  margin-left: 21px;
  background-color: #3b3b3b;
  color: white;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  text-align: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.45);
  user-select: none;
  transition: transform 550ms, box-shadow 550ms;
  will-change: transform;
}

.app__menuitem-number:hover,
.app__menuitem-number:focus {
  transform: translateY(10px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.7);
}

.app__menuitem-name {
  position: relative;
  font-weight: 700;
  letter-spacing: 0.05em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 30px;
  font-size: 20px;
  font-family: var(--font-base);
  user-select: none;
  z-index: 1;
}

.app__menuitem-sub {
  position: relative;
  margin-top: 0.2rem;
  font-family: var(--font-base);
  color: var(--color-gray);
  font-weight: 700;
  letter-spacing: 0.05em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 30px;
  font-size: 20px;
  user-select: none;
  z-index: 1;
}

.app__menuitem-price {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: -19px;
  background-color: #3b3b3b;
  color: white;
  border-radius: 10px;
  width: 140px;
  padding: 10px 0;
  line-height: 18px;
  font-size: 18px;
  text-align: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.45);
  user-select: none;
  transition: transform 550ms, box-shadow 550ms;
  will-change: transform;
}

.app__menuitem-price:hover,
.app__menuitem-price:focus {
  transform: translate(-50%, 10px);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.7);
}

.app__menuitem-dash {
  width: 100%;
  height: 0.01rem;
  background: var(--color-grey);
}
