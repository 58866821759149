.app__aboutus {
  position: relative;
}

.app__aboutus-content {
  width: 100%;
  margin-bottom: 150px;
  z-index: 2;
}

.app__aboutus-content_about {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: right;
}

.app__aboutus-content_txt {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  font-size: 32px;
  line-height: 41.6px;
  margin-bottom: 1rem;
}

.app__aboutus-content_lantern {
  margin: 2rem 4rem;
}

.app__aboutus-content_history {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  text-align: left;
}

.app__aboutus-content_about p,
.app__aboutus-content_history p {
  margin: 0.5rem 0;
  color: var(--color-grey);
}

@media screen and (min-width: 2000px) {
  .app__aboutus-content_about p,
  .app__aboutus-content_history p {
    margin: 4rem 0;
  }
}

@media screen and (max-width: 900px) {
  .app__aboutus-content {
    flex-direction: column;
  }

  .app__aboutus-content_lantern {
    margin: 4rem 0;
  }
}
